@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-footer {
  border-top: 1px solid var(--lia-bs-border-color);
  background-color: var(--lia-local-bg-color, transparent);
}

.lia-container {
  display: grid;
  grid-template-areas:
    'links logo'
    'build logo';
  padding-top: 25px;
  padding-bottom: 25px;

  @media (--lia-breakpoint-down-sm) {
    grid-template-areas:
      'links'
      'logo'
      'build';
  }
}

.lia-logo-wrap {
  grid-area: logo;
  display: flex;
  align-items: center;
  margin-left: auto;

  .lia-logo {
    fill: var(--lia-local-link-color, var(--lia-bs-gray-700));
  }
  &:hover,
  &:focus {
    .lia-logo {
      fill: var(--lia-local-link-hover-color, var(--lia-bs-gray-600));
    }
  }
  @media (--lia-breakpoint-down-sm) {
    margin-left: 0;
  }
}

.lia-footer-links {
  grid-area: links;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 15px;

  .lia-link {
    font-size: var(--lia-bs-font-size-sm);
    font-weight: var(--lia-font-weight-md);
    color: var(--lia-local-link-color, var(--lia-bs-gray-700));

    &:hover,
    &:focus {
      color: var(--lia-local-link-hover-color, var(--lia-bs-gray-600));
      text-decoration: underline;
    }
    &-disabled {
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
  button.lia-link {
    @include default-focus-outline();
  }

  @media (--lia-breakpoint-down-sm) {
    margin-bottom: 25px;
  }
}
